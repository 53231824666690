<template>
    <div>
        <h1>New Site Request</h1>
        <form @submit.prevent="newSiteRequest">
            <div class="row">
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Heading</label>
                    <input type="text" v-model="formFields.heading.val" class="form-control">
                </div>
                <div class="col-xs-12">
                    <label>Description</label>
                    <textarea v-model="formFields.description.val" class="form-control"></textarea>
                </div>
            </div>
            <button class="btn btn-default spacing_top">Save</button>
        </form>
    </div>
</template>

<script>
    import { store } from '@/store/BusinessObject.store.js';
    import helpers from '@/store/helpers';
    export default {
        name: 'New',
        data() {
            return {
                state: store.state,
                formFields: {
                    heading: { val: '', qsField: '[site_request_heading]' },
                    description: { val: '', qsField: '[site_request_desc]' },
                }
            }
        },
        created() {
            store.clearObjectData();
        },
        methods: {
            newSiteRequest: function () {
                store.api('new_site_request', helpers.getQSPairs(this.formFields)).then(result => {
                        this.$router.push({path: `/${ this.state.nav.base }/${ result.site_request_id }`});
                    }
                );
            }
        }
    }
</script>

<style scoped>

</style>