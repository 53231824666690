import siteRequestCache from '@/cache/siteRequest.cache';

export const siteRequestStore = {
	state: {
		name: 'site_request',
		title: 'Site Request',
		titlePlural: 'Site Requests',
		nav: {
			base: 'site_request',
			newView: true,
			views: [
				{ view: 'details', label: 'Details' }
			]
		},
		search: {
			endPoint: 'site_requests',
			formFields: {
				label: {
					val: '',
					qsField: '[fstrings][site_request_heading]',
					label: 'Heading',
					component: 'SearchTextInput'
				},
				site_request_dept: {
					val: 0,
					qsField: '[numbers][site_request_dept]',
					label: 'Department',
					component: 'SearchSelectInput',
					options: siteRequestCache.DEPARTMENTS
				},
				submitted_by: {
					val: '',
					qsField: '[numbers][sr.user_id]',
					label: 'Submitted By',
					component: 'SearchTypeaheadInput',
					cdiType: 'employee',
					toggleHide: true,
					defaultVal: '',
				},
				shared: {
					val: '',
					qsField: '[isnot][shared]',
					label: 'Needs to be shared',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
				pending: {
					val: 48,
                    trueValue: 48,
					qsField: '[in][status_id]',
					label: 'Pending',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: 48,
				},
				denied: {
					val: '',
                    trueValue: 49,
					qsField: '[in][status_id]',
					label: 'Denied',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
				approved: {
					val: 50,
                    trueValue: 50,
					qsField: '[in][status_id]',
					label: 'Approved',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: 50,
				},
				in_progress: {
					val: 107,
                    trueValue: 107,
					qsField: '[in][status_id]',
					label: 'In Progress',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: 107,
				},
				hold: {
					val: '',
                    trueValue: 231,
					qsField: '[in][status_id]',
					label: 'Hold',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
				completed: {
					val: '',
                    trueValue: 51,
					qsField: '[in][status_id]',
					label: 'Completed',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
				points: {
					val: '',
					qsField: '[isnot][points]',
					label: 'Needs points',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
				pageSize: {val: 24, qsField: '[page_size]'},
				searchIndex: {val: 0, qsField: '[search_index]'},
				direction: {val: 'DESC', qsField: '[direction]'},
				orderBy: {val: 'site_request_id', qsField: '[orderby]'}
			},
			resultFields: {
				site_request_id: {header: 'ID', link: 'site_request'},
				site_request_heading: {header: 'Heading', link: ''},
				name: {header: 'Requester', link: ''},
				site_request_desc: {header: 'Description', link: ''},
				status_name: {header: 'Progress'},
				site_request_date: {header: 'Date Requested'}
			}
		},
		object: {
			fields: [
				{
					name: 'site_request_id',
					label: 'Site Request ID',
					component: 'DetailsStatic'
				},
				{
					name: 'site_request_heading',
					label: 'Heading',
					component: 'DetailsStatic'
				},
				{
					name: 'submitted_by',
					label: 'Submitted By',
					component: 'DetailsStatic'
				},
				{
					name: 'status_id',
					label: 'Status',
					component: 'DetailsSelect',
					options: siteRequestCache.STATUSES
				},

			],
		},
	},
}