<template>
    <div>
        <div v-if="id">
            <h1>Site Request {{ state.object.data.site_request_id }}</h1>
            <Details :data="state.object.data" v-show="isCurrentView('details')" />

            <h3>Description</h3>
            {{ state.object.data.site_request_desc }}

            <h3>Comments / Notes</h3>
            <textarea class="form-control spacing_btm"
                      v-show="showNoteInput"
                      v-model="note">
            </textarea>
            <button @click="addNote" class="btn btn-default">Add Note</button>

            <div class="spacing_top" v-for="(note, index) in state.object.data.notes" v-bind:key="index">
                <div>
                    <strong>{{ note.note_by }} ({{ note.site_request_note_date }}):</strong>
                </div>
                <div class="note">
                    {{ note.site_request_note }}
                </div>
            </div>
        </div>

        <New v-else-if="isCurrentView('new')" />
        
        <Search v-else />
    </div>
</template>

<script>
    import { store } from '@/store/BusinessObject.store.js';
    import { siteRequestStore } from './SiteRequest.store.js';
    import Search from '@/components/businessObjects/utils/Search.vue';
    import Details from '@/components/businessObjects/utils/Details';
    import New from "@/components/businessObjects/site_request/New";

    export default {
        name: "Container",
        data() {
            return {
                state: store.state,
                siteRequestState: siteRequestStore.state,
                note: null,
                showNoteInput: false
            }
        },
        props: ['id'],
        components: {
            Search,
            Details,
            New
        },
        created() {
            store.initialize(this.siteRequestState);
            this.load();
        },
        methods: {
            load: function() {
                if (this.id) {
                    store.load(this.id, 'details')
                        .then(() => this.$appStore.setTitle([this.state.object.data.site_request_id, this.state.titlePlural]));
                }
            },
            isCurrentView: function(view) {
                return view === this.state.currentView;
            },
            addNote: function() {
                if (!this.showNoteInput) {
                    this.showNoteInput = true;

                    return;
                }

                if (!this.note) {
                    this.$appStore.errorBox('No text');

                    return;
                }

                store.api('add_note', {'params[site_request_note]': this.note}, 'object.data');
                this.note = null;
                this.showNoteInput = false;
            }
        },
        computed: {
            currentView: function() {
                return this.state.currentView;
            }
        },
        watch: {
            id: function () {
                this.load();
            },
            currentView: function() {
                if(this.currentView == 'search'){
                    window.location.reload()
                }
            }
        }
    }
</script>

<style scoped>
    .note {
        white-space: pre-line;
    }
</style>