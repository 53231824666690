export default {
	REASONS: [
		[6, 'Bug'],
		[7, 'Feature Request'],
		[8, 'Database Change'],
		[9, 'Customer'],
		[104, 'Hardware'],
		[105, 'Software'],
		[106, 'Maintenance']
	],
	STATUSES: [
		[48, 'Pending'],
		[49, 'Denied'],
		[50, 'Approved'],
		[107, 'In Progress'],
		[231, 'Hold'],
		[51, 'Completed']
	],
	DEPARTMENTS: [
		[0, 'None'],
		[1, 'Sales'],
		[2, 'Engineering'],
		[3, 'Accounting'],
		[4, 'Production'],
		[5, 'Technology'],
		[369, 'Customer']
	],
	VALUES: [
		['Unknown', 'Unknown'],
		['Small', 'Small'],
		['Small-Medium', 'Small-Medium'],
		['Medium', 'Medium'],
		['Medium-Large', 'Medium-Large'],
		['Large', 'Large'],
	]
}